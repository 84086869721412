<template>
  <div>
    <!-- <p class="headline black--text font-weight-bold">{{ packageName }}</p> -->
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 4" :key="n"
            class="mx-auto pa-1"
            width="300"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 4" :key="n"
            class="mx-auto pa-1"
            width="300"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="row ml-3 mr-3">
          <div class="col-md-3 d-flex justify-center" v-for="list in listQuotations" :key="list.id">
            <v-card
              class="mt-0"
              height="300"
              width="300"
              @click="gotToDetail(list.quotation.id)"
            >
              <!-- <v-img src="@/assets/images/pages/card-basic-brown-watch.jpg"></v-img> -->
              <v-card-title><div class="black--text font-weight-bold">{{ list.quotation.name }}</div></v-card-title>
              <v-card-text v-for="detail in list.quotation_detail" :key="detail.id">
                <p class="mb-1 font-weight-bold">{{ (detail.level === 'A') ? 'Nano' : 'Micro' }}</p>
                <p class="mb-1"><i class="fas fa-user mr-2 primary--text"></i>{{ detail.qty }} Influencer</p>
                <p class="mb-1 text-capitalize"><i class="fas fa-mobile-alt mr-2 primary--text"></i>{{ detail.format | labelFormat }} </p>
              </v-card-text>
              <v-card-actions class="primary pa-0" style="position: absolute; bottom: 0; width:100%">
                <v-btn v-if="list.quotation.status === 1"
                  color="primary"
                  block
                  dark
                  large
                  class="text-capitalize"
                >
                  <i class="fa fa-user-check mr-2" aria-hidden="true"></i> Pilih Influencer
                </v-btn>
                <v-btn v-else
                  color="primary"
                  block
                  dark
                  large
                  class="text-capitalize"
                >
                  <i class="fa fa-eye mr-2" aria-hidden="true"></i> Lihat Detail
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <br> 
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// import UnitInfluencer from './Unit.vue'
import axios from 'axios'

export default {
  components:{
    // UnitInfluencer
  },
  setup() {
    const isCardDetailsVisible = false
    const rating = ref(5)

    return {
      isCardDetailsVisible,
      rating,
      packageName:'',
      listQuotations:[],
      isLoading:true,
      microPackages:[],
      nanoPackages:[],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  mounted(){
    this.loadQuotations()
    // if(this.$route.query.item === 'basic'){
    //   this.packageName = 'Basic Package'
    // } else if (this.$route.query.item === 'premium'){
    //   this.packageName = 'Premium  Package'
    // } else if (this.$route.query.item === 'custom') {
    //   this.packageName = 'Custom Package'
    // } else {
    //   this.$router.push('error-404');
    // }
  },
  methods: {
    loadQuotations(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            this.listQuotations = response.data.data
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    gotToDetail(item){
      // this.$router.push('/campaign/choose-influencer'); 
      this.$router.push({name:'quotation-detail', query: {item: this.encryptData(item)}}); 
    },
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
<template>
  <v-row>
    <v-col cols="12">
        <list-quotation></list-quotation>
    </v-col>
  </v-row>
</template>

<script>
import ListQuotation from './components/ListQuotation.vue'

export default {
  components: {
    ListQuotation
  },
  setup() {
    return {}
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
